<template>
  <div
    class="item-dialog-edit__quote quote-edit"
  >
    <div
      class="quote-edit__container d-flex"
    >
      <item-dialog-bar 
        class="item-dialog__bar d-flex flex-column justify-center align-items-center pt-0"
        :type="'edit'"
        :item="item"
        @emitAction="onEmitAction"
      />
      <!-- <div
        class="quote-edit__block quote-edit__left"
      >
        <v-btn
          v-for="(size, sizeIndex) in canvasSizes"
          width="100%"
          :key="`size-${sizeIndex}`"
          @click="() => changeSize(sizeIndex)"
          :input-value="currentCanvasSize === sizeIndex"
        >
          {{ sizeIndex }}
        </v-btn>
      </div> -->
      <div
        class="quote-edit__block quote-edit__content pa-0 mx-0 flex-grow-1"
      >
        <beenokle-scene
          ref="scene"
          v-model="quoteScene"
          @inited="ref => scene = ref"
        />
      </div>
      <v-navigation-drawer
        right
        floating
        class="d-flex"
        style="transform: none !important;"
        permanent
        width="300"
      >
        <div
          class="pa-5 mt-0 flex-grow-1"
          style="height: 100%;"
        >
          <beenokle-scene-inspector
            ref="scene-inspector"
            style="height: 100%;"
            class="d-flex flex-column"
            v-if="scene"
            :scene="scene"
            v-model="quoteScene"
          />
        </div>
      </v-navigation-drawer>
    </div>
  </div>
</template>
<script>

const BeenokleScene = () => import('@/components/beenokle-scene')
const BeenokleSceneInspector = () => import('@/components/beenokle-scene/inspector/index')

import ItemDialogBar from '../../bar'


export default {
  props: {
    item: {
      required: true
    }
  },
  name: 'ItemEditQuote',
  components: {
    ItemDialogBar,
    BeenokleScene,
    BeenokleSceneInspector
  },
  data: () => ({
    scene: null,
    quoteScene: [
      {
        id: 'canvas',
        type: 'CANVAS',
        draggable: false,
        children: [],
        background: window.location.origin + '/img/temp-canvas-img.jpg',
        boundingBox: {
          x: 0,
          y: 0,
          width: 484,
          height: 242
        }
      }
    ],
    currentCanvasSize: 'fb',
    canvasSizes: {
      fb: {
        width: 484,
        height: 242
      },
      insta: {
        width: 484,
        height: 484
      },
      pinterest: {
        width: 484,
        height: 725
      }
    }
  }),
  methods: {
    onEmitAction(action) {
      const { id } = action

      switch (id) {
        case 'addQuoteBlock':
          this.onClick()
          break
      }
    },
    changeSize(sizeIndex) {
      this.currentCanvasSize = sizeIndex
      const { width, height } = this.canvasSizes[this.currentCanvasSize]
      
      const { scene } = this.$refs
      scene && scene.updateItem('canvas', {
        'boundingBox.width': width,
        'boundingBox.height': height
      })
    },
    onClick() {
      const { scene } = this.$refs
      scene && scene.addItem()
    }
  }
}
</script>
<style lang="scss">
.quote-edit {
  height: 100%;
  background-color: #fff;

  &__container {
    height: 100%;
  }

  &__content {
    position: relative;
    background: #fff !important;

    img {
      object-fit: cover;
      display: block;
    }
  }

  &__item {
    position: absolute;
  }

  &__block {
    margin: 16px 20px 0;
    background: #f8f8f8;
    padding: 12px 8px 0;
    border-radius: $border-radius-default;
  }

  &__left,
  &__right {
    max-width: 210px;
    width: 100%;
  }

  &__right {
    max-width: 325px;
  }
}
</style>